.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.login-row {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.navbar-top .nav-item .dropdown-menu {
  min-width: max-content !important;
}
